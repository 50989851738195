import router from "@/router"; //shortcut to src
import {ActionTree, Module} from "vuex";
import {LoginRequest, RecoverPasswordRequest, RecoverPasswordResponse, UserData} from "@/store/modules/auth/models";
import axios from "axios";

const localStorageKey = 'aoil:lk:user';

export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {
        userData(): UserData|null {
            const dataStr = localStorage.getItem(localStorageKey);
            if (null === dataStr) {
                return null;
            }

            const data = JSON.parse(dataStr);
            return data as UserData;
        }
    },
    mutations: {
    },
    actions: <ActionTree<any, any>>{
        async login(ctx, data: LoginRequest) {
            try {
                const response = await axios.post<UserData>(
                    `${ process.env.VUE_APP_URL }/authentication_token`,
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    }
                );
                localStorage.setItem(localStorageKey, JSON.stringify(response.data));
            } catch (err) {
                console.log(err);
                throw err;
            }
        },
        logout(ctx) {
            localStorage.removeItem(localStorageKey);
            router.push({ name: 'login' });
            ctx.dispatch('reset', null, {root: true});
        },
        async sendRecoverPasswordEmail(ctx, login: string) {
            await axios.post(
                `${ process.env.VUE_APP_URL }/recover_password_email`,
                {
                    login: login,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }
            );
        },
        async recoverPassword(ctx, request: RecoverPasswordRequest): Promise<RecoverPasswordResponse> {
            const response = await axios.post<RecoverPasswordResponse>(
                `${ process.env.VUE_APP_URL }/recover_password`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        }
    }
};
