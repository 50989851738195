import { createApp } from 'vue'
import Vuelidate from '@vuelidate/core'
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import '@/design/main.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Vuelidate as any)
  .use(Notifications)
  .mount('#app')
