<template>
  <v-app>
    <router-view/>
    <notifications />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {Notifications} from "@kyvg/vue3-notification";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'App',
  components: {Notifications},
  computed: {
    ...mapGetters('auth', [
        'userData',
    ]),
  },
  data () {
    return {
      //
    }
  },
  mounted() {
    if (this.userData === null && location.pathname !== '/login') {
      this.$router.push({ name: 'login' });
    }
  },
  watch: {
    userData() {
      if (this.userData === null) {
        this.$router.push({ name: 'login' });
      }
    }
  }
})
</script>
